.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(23 103 165);
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(23 103 165, 0.8);
}
