@tailwind base;
@tailwind components;
@tailwind utilities;

/* This is to avoid text position issue when downloading image */
@layer base {
  img {
      display: unset;
  }
}

@layer components {
  input[type="range"] {
    @apply appearance-none bg-transparent;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none bg-white border-2 border-red-500 drop-shadow-lg rounded-full cursor-pointer h-[18px] w-[18px] mt-2 pointer-events-auto relative;
  }

  input[type="range"]::-moz-range-thumb {
    @apply appearance-none bg-white border-2 border-red-500 drop-shadow-lg rounded-full cursor-pointer h-[18px] w-[18px] mt-2 pointer-events-auto relative;
  }
}
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}
