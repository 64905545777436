.editor {
  border: 1px solid #e9e9e9;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 16px;
}

.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5,
.editor h6 {
  color: #112e51;
}

.editor h1 {
  font-size: 1.8rem;
}

.editor h2 {
  font-size: 1.5rem;
}

.editor h3 {
  font-size: 1.3rem;
}

.editor h4 {
  font-size: 1rem;
}

.editor h5 {
  font-size: 0.8rem;
}

.editor h6 {
  font-size: 0.6rem;
}

.editor a {
  color: #0071bc;
  text-decoration: none;
}

.editor a:hover {
  text-decoration: underline;
  cursor: pointer;
}

pre {
  border: 1px solid #e9e9e9;
  padding: 1rem;
  background-color: #f4f4f4;
}
.rdw-link-modal {
  padding: 8px;
  width: 170px;
}
.rdw-link-modal label {
  font-size: 12px;
}
.rdw-link-modal-input {
  font-size: 12px;
}
.rdw-link-modal-buttonsection {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.rdw-link-modal-btn {
  margin-left: 0;
  padding: 5px 20px;
  width: fit-content;
  font-size: 12px;
}
.rdw-emoji-modal {
  padding: 8px;
  width: 150px;
}
.rdw-link-decorator-icon {
  left: 100%;
  top: -1px;
  width: 12px;
}
